import '../scss/Header.scss';

const Header = () => {
  return (
    <header>
      <h1>Cooking timers</h1>
      <h2>Set as many as u want</h2>
    </header>
  )
}

export default Header;
